<template>
  <!-- begin:: Header -->
  <div
    id="kt_header"
    ref="kt_header"
    class="header"
    v-bind:class="headerClasses"
  >
    <div
      class="container-fluid d-flex align-items-center justify-content-between"
    >
      <!-- begin:: Header Menu -->
      <div
        class="header-menu-wrapper header-menu-wrapper-left"
        ref="kt_header_menu_wrapper"
        v-if="addclass"
      >
        <div
          v-if="headerMenuEnabled"
          id="kt_header_menu"
          ref="kt_header_menu"
          class="header-menu header-menu-mobile"
          v-bind:class="headerMenuClasses"
        >
          <!-- example static menu here -->
          <!--<KTMenu></KTMenu>-->
        </div>
      </div>
      <!-- end:: Header Menu -->
      <KTSubheader
        v-if="addclass"
        v-bind:breadcrumbs="breadcrumbs"
        v-bind:title="pageTitle"
      />
      <!--begin::Logo-->
      <a href="/" v-if="!addclass">
        <img alt="Logo" :src="headerLogo" />
      </a>
      <!--end::Logo-->
      <KTTopbar></KTTopbar>
    </div>
  </div>
  <!-- end:: Header -->
</template>

<style scoped>
.aside-enabled .header.header-fixed.header-for-inspector,
.header.header-fixed.header-for-inspector {
  left: 0;
  background-color: #1a1a27;
}

@media (max-width: 991px) {
  .aside-enabled .header.header-fixed.header-for-inspector,
  .header.header-fixed.header-for-inspector {
    display: none;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import KTTopbar from "@/view/layout/header/Topbar.vue";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
//import KTMenu from "@/view/layout/header/Menu.vue";
import KTSubheader from "@/view/layout/subheader/Subheader.vue";

export default {
  name: "KTHeader",
  components: {
    KTTopbar,
    //KTMenu
    KTSubheader
  },
  props: {
    addclass: Boolean
  },
  mounted() {
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init("kt_header", "kt_header_mobile");

    // Init Header Menu
    KTLayoutHeaderMenu.init(
      this.$refs["kt_header_menu"],
      this.$refs["kt_header_menu_wrapper"]
    );
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses", "breadcrumbs", "pageTitle",]),

    /**
     * Check if the header menu is enabled
     * @returns {boolean}
     */
    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    headerLogo() {
      return process.env.BASE_URL + this.layoutConfig("self.logo.dark");
    },

    /**
     * Get extra classes for header based on the options
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        if (!this.addclass) {
          classes.push("header-for-inspector");
        }
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Get extra classes for header menu based on the options
     * @returns {null|*}
     */
    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    }
  }
};
</script>
